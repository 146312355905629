/**
 * This file was automatically generated by @cosmwasm/ts-codegen@1.10.0.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the @cosmwasm/ts-codegen generate command to regenerate this file.
 */

import { QueryClient, UseQueryOptions } from '@tanstack/react-query'

import {
  Addr,
  ClaimsResponse,
  Config,
  InfoResponse,
  ListStakersResponse,
  TotalPowerAtHeightResponse,
  VotingPowerAtHeightResponse,
} from '@dao-dao/types/contracts/DaoVotingNativeStaked'
import { getCosmWasmClientForChainId } from '@dao-dao/utils'

import { DaoVotingNativeStakedQueryClient } from '../../../contracts/DaoVotingNativeStaked'
import { contractQueries } from '../contract'
import { indexerQueries } from '../indexer'

export const daoVotingNativeStakedQueryKeys = {
  contract: [
    {
      contract: 'daoVotingNativeStaked',
    },
  ] as const,
  address: (chainId: string, contractAddress: string) =>
    [
      {
        ...daoVotingNativeStakedQueryKeys.contract[0],
        chainId,
        address: contractAddress,
      },
    ] as const,
  getConfig: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...daoVotingNativeStakedQueryKeys.address(chainId, contractAddress)[0],
        method: 'get_config',
        args,
      },
    ] as const,
  claims: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...daoVotingNativeStakedQueryKeys.address(chainId, contractAddress)[0],
        method: 'claims',
        args,
      },
    ] as const,
  listStakers: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...daoVotingNativeStakedQueryKeys.address(chainId, contractAddress)[0],
        method: 'list_stakers',
        args,
      },
    ] as const,
  votingPowerAtHeight: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...daoVotingNativeStakedQueryKeys.address(chainId, contractAddress)[0],
        method: 'voting_power_at_height',
        args,
      },
    ] as const,
  totalPowerAtHeight: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...daoVotingNativeStakedQueryKeys.address(chainId, contractAddress)[0],
        method: 'total_power_at_height',
        args,
      },
    ] as const,
  dao: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...daoVotingNativeStakedQueryKeys.address(chainId, contractAddress)[0],
        method: 'dao',
        args,
      },
    ] as const,
  info: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...daoVotingNativeStakedQueryKeys.address(chainId, contractAddress)[0],
        method: 'info',
        args,
      },
    ] as const,
}
export const daoVotingNativeStakedQueries = {
  getConfig: <TData = Config>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      options,
    }: DaoVotingNativeStakedGetConfigQuery<TData>
  ): UseQueryOptions<Config, Error, TData> => ({
    queryKey: daoVotingNativeStakedQueryKeys.getConfig(
      chainId,
      contractAddress
    ),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return await queryClient.fetchQuery(
          indexerQueries.queryContract(queryClient, {
            chainId,
            contractAddress,
            formula: 'daoVotingNativeStaked/config',
          })
        )
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new DaoVotingNativeStakedQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).getConfig()
    },
    ...options,
  }),
  claims: <TData = ClaimsResponse>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      args,
      options,
    }: DaoVotingNativeStakedClaimsQuery<TData>
  ): UseQueryOptions<ClaimsResponse, Error, TData> => ({
    queryKey: daoVotingNativeStakedQueryKeys.claims(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return {
          claims: await queryClient.fetchQuery(
            indexerQueries.queryContract(queryClient, {
              chainId,
              contractAddress,
              formula: 'daoVotingNativeStaked/claims',
              args,
            })
          ),
        }
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new DaoVotingNativeStakedQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).claims({
        address: args.address,
      })
    },
    ...options,
  }),
  listStakers: <TData = ListStakersResponse>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      args,
      options,
    }: DaoVotingNativeStakedListStakersQuery<TData>
  ): UseQueryOptions<ListStakersResponse, Error, TData> => ({
    queryKey: daoVotingNativeStakedQueryKeys.listStakers(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return {
          stakers: await queryClient.fetchQuery(
            indexerQueries.queryContract(queryClient, {
              chainId,
              contractAddress,
              formula: 'daoVotingNativeStaked/listStakers',
              args,
            })
          ),
        }
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new DaoVotingNativeStakedQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).listStakers({
        limit: args.limit,
        startAfter: args.startAfter,
      })
    },
    ...options,
  }),
  votingPowerAtHeight: <TData = VotingPowerAtHeightResponse>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      args,
      options,
    }: DaoVotingNativeStakedVotingPowerAtHeightQuery<TData>
  ): UseQueryOptions<VotingPowerAtHeightResponse, Error, TData> => ({
    queryKey: daoVotingNativeStakedQueryKeys.votingPowerAtHeight(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return await queryClient.fetchQuery(
          indexerQueries.queryContract(queryClient, {
            chainId,
            contractAddress,
            formula: 'daoVotingNativeStaked/votingPowerAtHeight',
            args: {
              address: args.address,
            },
            ...(args.height && { block: { height: args.height } }),
          })
        )
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new DaoVotingNativeStakedQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).votingPowerAtHeight({
        address: args.address,
        height: args.height,
      })
    },
    ...options,
  }),
  totalPowerAtHeight: <TData = TotalPowerAtHeightResponse>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      args,
      options,
    }: DaoVotingNativeStakedTotalPowerAtHeightQuery<TData>
  ): UseQueryOptions<TotalPowerAtHeightResponse, Error, TData> => ({
    queryKey: daoVotingNativeStakedQueryKeys.totalPowerAtHeight(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return await queryClient.fetchQuery(
          indexerQueries.queryContract(queryClient, {
            chainId,
            contractAddress,
            formula: 'daoVotingNativeStaked/totalPowerAtHeight',
            ...(args.height && { block: { height: args.height } }),
          })
        )
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new DaoVotingNativeStakedQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).totalPowerAtHeight({
        height: args.height,
      })
    },
    ...options,
  }),
  dao: <TData = Addr>(
    queryClient: QueryClient,
    { chainId, contractAddress, options }: DaoVotingNativeStakedDaoQuery<TData>
  ): UseQueryOptions<Addr, Error, TData> => ({
    queryKey: daoVotingNativeStakedQueryKeys.dao(chainId, contractAddress),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return await queryClient.fetchQuery(
          indexerQueries.queryContract(queryClient, {
            chainId,
            contractAddress,
            formula: 'daoVotingNativeStaked/dao',
          })
        )
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new DaoVotingNativeStakedQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).dao()
    },
    ...options,
  }),
  info: contractQueries.info,
}
export interface DaoVotingNativeStakedReactQuery<TResponse, TData = TResponse> {
  chainId: string
  contractAddress: string
  options?: Omit<
    UseQueryOptions<TResponse, Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: undefined
  }
}
export interface DaoVotingNativeStakedInfoQuery<TData>
  extends DaoVotingNativeStakedReactQuery<InfoResponse, TData> {}
export interface DaoVotingNativeStakedDaoQuery<TData>
  extends DaoVotingNativeStakedReactQuery<Addr, TData> {}
export interface DaoVotingNativeStakedTotalPowerAtHeightQuery<TData>
  extends DaoVotingNativeStakedReactQuery<TotalPowerAtHeightResponse, TData> {
  args: {
    height?: number
  }
}
export interface DaoVotingNativeStakedVotingPowerAtHeightQuery<TData>
  extends DaoVotingNativeStakedReactQuery<VotingPowerAtHeightResponse, TData> {
  args: {
    address: string
    height?: number
  }
}
export interface DaoVotingNativeStakedListStakersQuery<TData>
  extends DaoVotingNativeStakedReactQuery<ListStakersResponse, TData> {
  args: {
    limit?: number
    startAfter?: string
  }
}
export interface DaoVotingNativeStakedClaimsQuery<TData>
  extends DaoVotingNativeStakedReactQuery<ClaimsResponse, TData> {
  args: {
    address: string
  }
}
export interface DaoVotingNativeStakedGetConfigQuery<TData>
  extends DaoVotingNativeStakedReactQuery<Config, TData> {}
