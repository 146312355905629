/**
 * This file was automatically generated by @cosmwasm/ts-codegen@1.10.0.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the @cosmwasm/ts-codegen generate command to regenerate this file.
 */

import { QueryClient, UseQueryOptions } from '@tanstack/react-query'

import {
  ActiveThresholdResponse,
  Addr,
  Boolean,
  ClaimsResponse,
  Config,
  DenomResponse,
  GetHooksResponse,
  InfoResponse,
  ListStakersResponse,
  NullableAddr,
  TotalPowerAtHeightResponse,
  VotingPowerAtHeightResponse,
} from '@dao-dao/types/contracts/DaoVotingTokenStaked'
import { getCosmWasmClientForChainId } from '@dao-dao/utils'

import { DaoVotingTokenStakedQueryClient } from '../../../contracts/DaoVotingTokenStaked'
import { contractQueries } from '../contract'
import { indexerQueries } from '../indexer'

export const daoVotingTokenStakedQueryKeys = {
  contract: [
    {
      contract: 'daoVotingTokenStaked',
    },
  ] as const,
  address: (chainId: string, contractAddress: string) =>
    [
      {
        ...daoVotingTokenStakedQueryKeys.contract[0],
        chainId,
        address: contractAddress,
      },
    ] as const,
  getConfig: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...daoVotingTokenStakedQueryKeys.address(chainId, contractAddress)[0],
        method: 'get_config',
        args,
      },
    ] as const,
  claims: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...daoVotingTokenStakedQueryKeys.address(chainId, contractAddress)[0],
        method: 'claims',
        args,
      },
    ] as const,
  listStakers: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...daoVotingTokenStakedQueryKeys.address(chainId, contractAddress)[0],
        method: 'list_stakers',
        args,
      },
    ] as const,
  activeThreshold: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...daoVotingTokenStakedQueryKeys.address(chainId, contractAddress)[0],
        method: 'active_threshold',
        args,
      },
    ] as const,
  getHooks: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...daoVotingTokenStakedQueryKeys.address(chainId, contractAddress)[0],
        method: 'get_hooks',
        args,
      },
    ] as const,
  tokenContract: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...daoVotingTokenStakedQueryKeys.address(chainId, contractAddress)[0],
        method: 'token_contract',
        args,
      },
    ] as const,
  denom: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...daoVotingTokenStakedQueryKeys.address(chainId, contractAddress)[0],
        method: 'denom',
        args,
      },
    ] as const,
  isActive: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...daoVotingTokenStakedQueryKeys.address(chainId, contractAddress)[0],
        method: 'is_active',
        args,
      },
    ] as const,
  votingPowerAtHeight: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...daoVotingTokenStakedQueryKeys.address(chainId, contractAddress)[0],
        method: 'voting_power_at_height',
        args,
      },
    ] as const,
  totalPowerAtHeight: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...daoVotingTokenStakedQueryKeys.address(chainId, contractAddress)[0],
        method: 'total_power_at_height',
        args,
      },
    ] as const,
  dao: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...daoVotingTokenStakedQueryKeys.address(chainId, contractAddress)[0],
        method: 'dao',
        args,
      },
    ] as const,
  info: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...daoVotingTokenStakedQueryKeys.address(chainId, contractAddress)[0],
        method: 'info',
        args,
      },
    ] as const,
}
export const daoVotingTokenStakedQueries = {
  getConfig: <TData = Config>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      options,
    }: DaoVotingTokenStakedGetConfigQuery<TData>
  ): UseQueryOptions<Config, Error, TData> => ({
    queryKey: daoVotingTokenStakedQueryKeys.getConfig(chainId, contractAddress),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return await queryClient.fetchQuery(
          indexerQueries.queryContract(queryClient, {
            chainId,
            contractAddress,
            formula: 'daoVotingTokenStaked/config',
          })
        )
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new DaoVotingTokenStakedQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).getConfig()
    },
    ...options,
  }),
  claims: <TData = ClaimsResponse>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      args,
      options,
    }: DaoVotingTokenStakedClaimsQuery<TData>
  ): UseQueryOptions<ClaimsResponse, Error, TData> => ({
    queryKey: daoVotingTokenStakedQueryKeys.claims(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return await queryClient.fetchQuery(
          indexerQueries.queryContract(queryClient, {
            chainId,
            contractAddress,
            formula: 'daoVotingTokenStaked/claims',
            args,
          })
        )
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new DaoVotingTokenStakedQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).claims({
        address: args.address,
      })
    },
    ...options,
  }),
  listStakers: <TData = ListStakersResponse>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      args,
      options,
    }: DaoVotingTokenStakedListStakersQuery<TData>
  ): UseQueryOptions<ListStakersResponse, Error, TData> => ({
    queryKey: daoVotingTokenStakedQueryKeys.listStakers(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return await queryClient.fetchQuery(
          indexerQueries.queryContract(queryClient, {
            chainId,
            contractAddress,
            formula: 'daoVotingTokenStaked/listStakers',
            args,
          })
        )
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new DaoVotingTokenStakedQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).listStakers({
        limit: args.limit,
        startAfter: args.startAfter,
      })
    },
    ...options,
  }),
  activeThreshold: <TData = ActiveThresholdResponse>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      options,
    }: DaoVotingTokenStakedActiveThresholdQuery<TData>
  ): UseQueryOptions<ActiveThresholdResponse, Error, TData> => ({
    queryKey: daoVotingTokenStakedQueryKeys.activeThreshold(
      chainId,
      contractAddress
    ),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return {
          active_threshold: await queryClient.fetchQuery(
            indexerQueries.queryContract(queryClient, {
              chainId,
              contractAddress,
              formula: 'daoVotingTokenStaked/activeThreshold',
            })
          ),
        }
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new DaoVotingTokenStakedQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).activeThreshold()
    },
    ...options,
  }),
  getHooks: <TData = GetHooksResponse>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      options,
    }: DaoVotingTokenStakedGetHooksQuery<TData>
  ): UseQueryOptions<GetHooksResponse, Error, TData> => ({
    queryKey: daoVotingTokenStakedQueryKeys.getHooks(chainId, contractAddress),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return await queryClient.fetchQuery(
          indexerQueries.queryContract(queryClient, {
            chainId,
            contractAddress,
            formula: 'daoVotingTokenStaked/getHooks',
          })
        )
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new DaoVotingTokenStakedQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).getHooks()
    },
    ...options,
  }),
  tokenContract: <TData = NullableAddr>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      options,
    }: DaoVotingTokenStakedTokenContractQuery<TData>
  ): UseQueryOptions<NullableAddr, Error, TData> => ({
    queryKey: daoVotingTokenStakedQueryKeys.tokenContract(
      chainId,
      contractAddress
    ),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return await queryClient.fetchQuery(
          indexerQueries.queryContract(queryClient, {
            chainId,
            contractAddress,
            formula: 'daoVotingTokenStaked/tokenIssuerContract',
          })
        )
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new DaoVotingTokenStakedQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).tokenContract()
    },
    ...options,
  }),
  denom: <TData = DenomResponse>(
    queryClient: QueryClient,
    { chainId, contractAddress, options }: DaoVotingTokenStakedDenomQuery<TData>
  ): UseQueryOptions<DenomResponse, Error, TData> => ({
    queryKey: daoVotingTokenStakedQueryKeys.denom(chainId, contractAddress),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return {
          denom: await queryClient.fetchQuery(
            indexerQueries.queryContract(queryClient, {
              chainId,
              contractAddress,
              formula: 'daoVotingTokenStaked/denom',
            })
          ),
        }
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new DaoVotingTokenStakedQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).denom()
    },
    ...options,
  }),
  isActive: <TData = Boolean>({
    chainId,
    contractAddress,
    options,
  }: DaoVotingTokenStakedIsActiveQuery<TData>): UseQueryOptions<
    Boolean,
    Error,
    TData
  > => ({
    queryKey: daoVotingTokenStakedQueryKeys.isActive(chainId, contractAddress),
    queryFn: async () => {
      return new DaoVotingTokenStakedQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).isActive()
    },
    ...options,
  }),
  votingPowerAtHeight: <TData = VotingPowerAtHeightResponse>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      args,
      options,
    }: DaoVotingTokenStakedVotingPowerAtHeightQuery<TData>
  ): UseQueryOptions<VotingPowerAtHeightResponse, Error, TData> => ({
    queryKey: daoVotingTokenStakedQueryKeys.votingPowerAtHeight(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return await queryClient.fetchQuery(
          indexerQueries.queryContract(queryClient, {
            chainId,
            contractAddress,
            formula: 'daoVotingTokenStaked/votingPowerAtHeight',
            args: {
              address: args.address,
            },
            ...(args.height && { block: { height: args.height } }),
          })
        )
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new DaoVotingTokenStakedQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).votingPowerAtHeight({
        address: args.address,
        height: args.height,
      })
    },
    ...options,
  }),
  totalPowerAtHeight: <TData = TotalPowerAtHeightResponse>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      args,
      options,
    }: DaoVotingTokenStakedTotalPowerAtHeightQuery<TData>
  ): UseQueryOptions<TotalPowerAtHeightResponse, Error, TData> => ({
    queryKey: daoVotingTokenStakedQueryKeys.totalPowerAtHeight(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return await queryClient.fetchQuery(
          indexerQueries.queryContract(queryClient, {
            chainId,
            contractAddress,
            formula: 'daoVotingTokenStaked/totalPowerAtHeight',
            ...(args.height && { block: { height: args.height } }),
          })
        )
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new DaoVotingTokenStakedQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).totalPowerAtHeight({
        height: args.height,
      })
    },
    ...options,
  }),
  dao: <TData = Addr>(
    queryClient: QueryClient,
    { chainId, contractAddress, options }: DaoVotingTokenStakedDaoQuery<TData>
  ): UseQueryOptions<Addr, Error, TData> => ({
    queryKey: daoVotingTokenStakedQueryKeys.dao(chainId, contractAddress),
    queryFn: async () => {
      try {
        // Attempt to fetch data from the indexer.
        return await queryClient.fetchQuery(
          indexerQueries.queryContract(queryClient, {
            chainId,
            contractAddress,
            formula: 'daoVotingTokenStaked/dao',
          })
        )
      } catch (error) {
        console.error(error)
      }

      // If indexer query fails, fallback to contract query.
      return new DaoVotingTokenStakedQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).dao()
    },
    ...options,
  }),
  info: contractQueries.info,
}
export interface DaoVotingTokenStakedReactQuery<TResponse, TData = TResponse> {
  chainId: string
  contractAddress: string
  options?: Omit<
    UseQueryOptions<TResponse, Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: undefined
  }
}
export interface DaoVotingTokenStakedInfoQuery<TData>
  extends DaoVotingTokenStakedReactQuery<InfoResponse, TData> {}
export interface DaoVotingTokenStakedDaoQuery<TData>
  extends DaoVotingTokenStakedReactQuery<Addr, TData> {}
export interface DaoVotingTokenStakedTotalPowerAtHeightQuery<TData>
  extends DaoVotingTokenStakedReactQuery<TotalPowerAtHeightResponse, TData> {
  args: {
    height?: number
  }
}
export interface DaoVotingTokenStakedVotingPowerAtHeightQuery<TData>
  extends DaoVotingTokenStakedReactQuery<VotingPowerAtHeightResponse, TData> {
  args: {
    address: string
    height?: number
  }
}
export interface DaoVotingTokenStakedIsActiveQuery<TData>
  extends DaoVotingTokenStakedReactQuery<Boolean, TData> {}
export interface DaoVotingTokenStakedDenomQuery<TData>
  extends DaoVotingTokenStakedReactQuery<DenomResponse, TData> {}
export interface DaoVotingTokenStakedTokenContractQuery<TData>
  extends DaoVotingTokenStakedReactQuery<NullableAddr, TData> {}
export interface DaoVotingTokenStakedGetHooksQuery<TData>
  extends DaoVotingTokenStakedReactQuery<GetHooksResponse, TData> {}
export interface DaoVotingTokenStakedActiveThresholdQuery<TData>
  extends DaoVotingTokenStakedReactQuery<ActiveThresholdResponse, TData> {}
export interface DaoVotingTokenStakedListStakersQuery<TData>
  extends DaoVotingTokenStakedReactQuery<ListStakersResponse, TData> {
  args: {
    limit?: number
    startAfter?: string
  }
}
export interface DaoVotingTokenStakedClaimsQuery<TData>
  extends DaoVotingTokenStakedReactQuery<ClaimsResponse, TData> {
  args: {
    address: string
  }
}
export interface DaoVotingTokenStakedGetConfigQuery<TData>
  extends DaoVotingTokenStakedReactQuery<Config, TData> {}
